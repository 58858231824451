@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-primary {
  background: #24367e !important;
  color: #ffffff;
}

.paybillnumbers {
  /* background: #1c75bc4d!important; */
  /* background-position: -20% 20%; */
  /* background-size: 100%; */
  box-shadow: 0 0 6px 0 #00000080;
  /* margin-bottom: 16px; */
  margin-top: 15px;
  /* padding-bottom: 0; */
  /* position: relative; */
  border-radius: 4px !important;
  text-align: center;
  z-index: 1;
}
.paybillnumbers h2 {
  color: #fff;
  font-size: 14px;
  font-weight: 800;
  letter-spacing: .8px;
  padding-bottom: 0px;
  padding-top: 10px;
  text-transform: uppercase;
}
.paybillnumbers ul li {
  align-items: center;
  background-color: #ffffff12;
  border-top: 1px solid #ffffff80;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 5px;
  padding-top: 5px;
}
.paybillnumbers p {
  font-size: 12px;
  padding: 8px;
}
.paybillnumbers ul li span {
  font-weight: 700;
  padding: 6px 0px 0 12px;
  font-size: 25px;
}

.bg-pink {
  background: rgba(231, 6, 84, 0.958);
}

.betslip-sport-icon {
  width: 30px;
  display: inline-block;
  margin: -10px -5px -5px;
}

.bet-option {
  /* font-weight: 600; */
}

.home-tabs {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 3px solid transparent;
  text-align: center;
  transition: all ease-in 1s;
}
.home-tab-active {
  border-bottom-color: rgba(231, 6, 84, 0.958);
  color: rgba(231, 6, 84, 0.958);
}

.home-tabs:hover {
  border-bottom-color: rgba(231, 6, 84, 0.958);
  color: rgba(231, 6, 84, 0.958);
  /* opacity: 0.; */
  transition: all ease-in 1s;
}

.btn-transparent-selector {
  font-weight: bold;
  font-size: 15px;

}

.homepage {
  background-color: #ffffff;
}

.bg-primary {
  border-bottom: 1px solid #ddd;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 20px;
  margin-bottom: 15px;
}

h5, h4, h3, h2 {
  font-weight: 500;
  margin-bottom: 10px;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0px 12px;
  font-size: 14px;
  line-height: 1.6;
  color: #504d4d;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #dddddd;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  -moz-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.pro-sidebar {
  color: #adadad;
  height: 100%;
  width: 100% !important;
  max-width: 240px !important;
  min-width: 240px !important;
  text-align: left;
  transition: width, left, right, 0.3s;
  position: relative;
  z-index: 1009;
}

.secondary-text {
  color: #FFB200;
}

.bright-blue-text {
  color: #00A8FA;
}

.tertiary-text {

}

.user-menu-col {
  width: auto;
  margin-top: 9px;
  margin-right: 10px;
}

.primary-text {
  color: #444444;
}

.ps-sidebar-container {
  background: #ffffff !important;
  color: #666666 !important;
}

.css-1wvake5, .ps-sidebar-root {
  position: relative;
  border-right-width: 1px;
  border-right-style: solid;
  border-color: #efefef;
  transition: width,left,right,300ms;
  width: auto !important;
  min-width: auto !important;
  height: 100%;
  box-shadow: 0 1px 2px rgba(0,0,0,0.41);
  background: #fff;
}

.ps-menu-button {
  border-bottom: 1px solid #dddddd;
}

.ps-menu-button:hover, .ps-menu-button:active, .ps-menu-button:focus {
  background-color: #ffffff !important;
  outline: none !important;
  border: none !important;
  border-bottom: 1px solid #dddddd !important;

}
.ps-menu-button {
  border-bottom: 1px solid #dddddd !important;
  display: flex;
  align-items: center;
  height: 37px !important;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  box-sizing: border-box;
  cursor: pointer;
  padding-right: 20px;
  padding-left: 5px !important;
  border-bottom: 1px solid #dddddd !important;

}

.ps-submenu-content {
  padding: 0;
  width: 100%;
  border-right: 1px solid #ccc;
  background: #f5f5f5 !important;
  margin: 0px auto 0;
}

.pc .navbar-nav > li > a {
  margin-right: 0;
  margin-left: 0;
  font-size: 15px;
  color: #fff;
  /* font-weight: bold; */
}

.space-icons {
  padding-right: 5px;
}

.min-scroll-table-content {
  max-height: 400px !important;
  overflow-y: scroll;
  display: block;
}

.offcanvas.show:not(.hiding), .offcanvas.showing {
  transform: none;
  z-index: 9999;
  background: #f3f4f6;
}

.header-account .cd {
  position: relative;
  display: block;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.25rem;
  color: rgb(90 102 120);
  font-weight: 500;
}
.header-account .cd a, .header-account .cd div, .header-account .cd-l {
  display: flex;
  cursor: pointer;
  padding: 1.3rem 1rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .15s;
}
.header-account .bx {
  width: 100%;
  align-items: center;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255);
  padding: 1rem;
  font-weight: 500;
}
.top-nav .slip-counter {
  background: #bc1b56;
  text-align: center;
  color: #fff;
  font-size: 12px;
  padding: 0 2px;
  display: inline-block;
  margin: -5px -1px -5px;
  border-radius: 100px;
  width: 20px;
  position: absolute;
  top: 0px;
  right: 0;
}

/* custom mobile horizontal scrollbar */

@media screen and (max-width:768px){
  .mobile-custom-scrollbar {
    scrollbar-width: none !important;
    scrollbar-color: transparent transparent;
}


.main-tabs.reduced-mobile-text {
  font-size: 11px;
}

.pc .navbar-nav > li > a {
  margin-right: 0;
  margin-left: 0;
  font-size: 13px;
  color: rgba(255,255,255,0.8);
  font-weight: bold;
  text-wrap: nowrap;
}

.offcanvas.show:not(.hiding), .offcanvas.showing {
  transform: none;
  width: 90%;
  background: #f0f0f0;
  z-index: 9999;
}
.canvas-content-box-separator {
  height: 0px;
  width: 100%;
  background: #ffffff;
  margin-top: 10px;
  border-bottom: 1px solid #000000;
  margin-bottom: 10px;
}
.off-canvas-content-box {
  background: #ffffff;
  padding: 15px;
  border-radius: 5px;
}
.offcanvas-header {
 
}
.main-tabs-submenu {
  text-wrap: nowrap;
  display: inline-block;
  margin: 0px;
  width: auto;
}
.c-btn-group {
  margin-left: 0% !important;
}
.more-markets-container {
  min-width: 28px !important;
  display: block;
  position: absolute;
  right: 10px;
  font-weight: bolder !important;
  text-align: right !important;
  padding-right: 0 !important;
  bottom: 10%;
}

.carousel-indicators {
  position: absolute;
  bottom: -20px;
  left: 50%;
  z-index: 15;
  width: 60%;
  margin-left: -30%;
  padding-left: 0;
  list-style: none;
  text-align: center;
}

.canvas-links li a {
  padding-top: 8px;
  padding-bottom: 7px;
  font-weight: 500;
  width: 100%;
  display: block;
  color: #a0a0a0;
}

.canvas-links li a:hover {
  color: #444444;
}

.canvas-links svg {
  margin-right: 5px;
  color: #888;
  font-size: 16px;
}

.offcanvas-title {
  margin-bottom: 0;
  font-size: 18px;
  line-height: var(--bs-offcanvas-title-line-height);
  font-weight: bold;
}

/* .amt {
  margin-top: 85px !important;
  scroll-margin-top: 140px;
} */

.offcanvas-header .btn-close {
  
}

.justify-content-between {
  padding-right: 0 !important;
  position: relative;
}
.matches .top-matches {
  background: #fff;
  padding-top: 10px;
  padding-bottom: 3px;
  margin-bottom: 0px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  font-weight: normal;
  color: #797979;
  font-size: 12px;
  margin: 0px;
}
.match-detail-container {
  width: 42% !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 6%;
  /* max-width: 20% !important; */
  /* margin-right: 2% !important; */
}
.navbar-nav > li > a {
  line-height: 12px;
}

.hidden-icons .space-icons {
  display: none;
}
.header-menu li {
  box-shadow: none !important;
  border-radius: 0;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-right: 0px;
  padding-bottom: 5px;
  border-bottom: 3px solid transparent;
  margin-left: 5px;
  padding-top: 5px;
}
}

.mobile-second-nav-icon {
  width: 40px !important;
  height: 25px;
}

.mobile-categories-item {
  margin-right: 6px;
  padding-right: 13px !important;
  font-size: 11px;
  padding-left: 13px !important;
}

.mobile-betslip-link {
  display: inline-table;
  background: rgba(255,255,255, 0.3);
  padding: 7px;
  position: absolute;
  right: 0;
  /* margin-left: 9px; */
  height: 100%;
  /* bottom: 0; */
  top: 0px;
}
.mobile-menu-item-wrap {
  text-wrap: nowrap;
  line-height: 1;
}

.ps-menu-button.ps-open {
  background: #ffffff;
  font-weight: 500;
}

.country-competition-item {
  padding-left: 20px;
  font-size: 12px;
}

.force-reduce-img img {
  height: 20px !important;
  opacity: 0.4;
}

:focus-visible {
  outline: 1px solid #3e1bf5;
}

.no-events-div {
  border: 2px dashed #dddddd;
  font-weight: 700;
  color: #aaaaaa;
}

.resp-sharing-button__icon svg {
  width: 30px;
}

.bonge-bonus {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  background: #24367e3d;
  color: #041b7a;
  border-radius: 4px;
  font-weight: 400;
}
.is-jackpot-bet-group-btns {
  margin-left: auto !important
}

.my-bets-header {
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #888;
  margin-bottom: 10px;
}

.results-icon {
  display: inline-block;
}

.results-icon.won {
  color: #00A8FA;
}
.results-icon.lost {
  color: #999999;
}

.table>:not(caption)>*>* {
  padding: 0;
  border-bottom: none;
}

.my-bets td {
  padding: 4px;
}

.betslip-header td {
  padding: 5px 10px;
}


.mybet-list .accordion__panel {
  padding: 10px 10px 20px;
}

.mybet-list table {
  margin-top: 10px !important;
}

.mybet-list thead td {
  background: rgba(0,0,0,0.020);
}

tr.my-bets {
  border-bottom: 2px solid #ffffff;
}
tr.my-bets td {
  background: rgba(0,0,0, 0.042) !important;
}

.accordion .mybet-list {
  margin-bottom: 6px;
  width: 98%;
  margin-left: auto;
  border-radius: 5px;
  margin-right: auto;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.071);
  -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.31);
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.071);
}

.accordion {
  border: 0;
  border-radius: 2px;
}

.accordion__button {
  background-color: rgba(0,0,0,0.04);
  /* color: #444; */
  cursor: pointer;
  padding: 18px 4px !important;
  width: 100%;
  font-weight: 500;
  text-align: left;
  border: none;
}

.accordion {
  border: none !important;
}

.cancel-bet {
  border: 2px solid #FFB200 !important;
  border-radius: 5px !important;
  outline: none;
  height: auto;
  font-weight: 500;
  margin-right: 7px;
  font-size: 12px;
}

.btn-sm {
  padding: 5px 12px !important;
}

.mybet-list .accordion__button:before {
  display: none;
}



.btn-bet-hist {
  background: #99999E;
  color: #17242F;
  text-transform: uppercase;
  margin-left: 3px;
  font-weight: bold;
  font-size: 12px;
  border-radius: 3px;
  padding: 5px 10px;
  white-space: nowrap;
}

.btn-bet-hist.active-bet {
  color: #FFF;
  background: #19BC54;
  font-weight: 500;
}

.btn-bet-hist.won-bet {
  color: #ffffff;
  background: #0000ffad;
}


.light-btn {
  background: transparent;
  border: 1px solid #aaaaaa !important;
}


.open-close-accordion {
  font-size: 22px;
  font-weight: bolder;
  margin-top: 5px;
  color: #888888;
  margin-left: 20px;
}

.mobile-download-banner {
  background: #fff;
  border-bottom: 1px solid rgba(0,0,0,0.2);
}

.mobile-app-download-btn {
  color: #fff;
  font-weight: 500;
  background: rgba(231,6,84,.958);
  font-weight: bold;
  text-transform: uppercase;
  font-size: 11px !important;
  padding: 5px 5px;
}

.ticker-module_tickerText__ryjAR {
  color: #666!important;
  font-size: 16px !important;
  line-height: 1.5;
  padding: 0 16px;
  white-space: nowrap;
}
.toggle-show-top-nav {
  position: absolute;
  left: 0;
  /* width: 20px; */
  top: 0;
  /* box-shadow: 0px 0px 3px rgba(255,255,255,.7); */
  /* background: black; */
  z-index: 9999;
}

.light-blue {
  background: #071960;
}

.btn-deposit-withdraw {
  border: #00A8FA;
  /* margin-top: 5px; */
  background: #0879b1;
  padding: 5px 15px;
  font-weight: 500;
  text-transform: uppercase;
}

.matches .top-matches .match-detail button {
  transition: all ease-in-out 100ms;
  -moz-transition: all ease-in-out 100ms;
  -webkit-transition: all ease-in-out 100ms;
}
.matches .top-matches .match-detail button:hover {
  transition: all ease-in-out 100ms;
  -moz-transition: all ease-in-out 100ms;
  -webkit-transition: all ease-in-out 100ms;
}

.popover-login-modal {
  /* background: #ffffff; */
}

.popover-login-modal .modal-fullscreen .modal-content {
  height: auto;
  border: 0;
  border-radius: 0;
}
.jackpot-header {
  background: #e7e7e7;
  box-shadow: 0 1px 4px rgba(0,0,0,0.2);
  -webkit-box-shadow: 0 1px 4px rgba(0,0,0,0.2);
  -moz-box-shadow: 0 1px 4px rgba(0,0,0,0.2);
  border-bottom: 1px solid rgba(255,255,255,0.4);
  padding-top: 5px;
  font-weight: 500;
  margin-right: 0;
  margin-left: 0;
}
.autopick-button-div {
  text-align: right;
}

.btn-auto-pick {
  height: 40px;
  width: 100%;
  max-width: 244px;
  padding: 0px;
  border-radius: 8px;
  background: rgb(255, 178, 0) !important;
  border: 0px;
  cursor: pointer;
  color: rgb(19, 30, 40);
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-transform: uppercase;
  transition: all ease-in 0.3s;
  -webkit-transition: all ease-in 0.3s;
  -moz-transition: all ease-in 0.3s;
}

.btn-auto-pick:hover {
  opacity: 0.6;
  transition: all ease-in 0.3s;
  -webkit-transition: all ease-in 0.3s;
  -moz-transition: all ease-in 0.3s;
}

input.std-input {
  /* border: 1px solid var(--light-gray); */
  height: 40px;
  /* width: calc(100% - 20px); */
  padding: 2.5px 10px;
  border-radius: 8px;
  background: #e8e8e8;
  /* color: var(--black); */
}

:disabled {
  cursor: not-allowed !important;
}

.betslip-game {
  margin-top: 5px;
  margin-bottom: 4px;
}
.betslip {
  font-size: 12px;
  /* margin-bottom: 30px; */
  position: relative;
  color: #070707;
}

.yellow-btn {
  background: rgb(251, 215, 2) !important;
  border: 1px solid rgb(211, 180, 6);
  color: #070707;

}

.yellow-btn:hover {
  opacity: 0.8;

}

.sidebar-link.active {
  font-weight: bold;

}

.main-tabs-submenu {
  border-bottom: 3px solid  transparent;
  padding: 7px 0;
  font-weight: 500;
  transition: all ease-in 0.4s;
  -moz-transition: all ease-in 0.4s;
  -webkit-transition: all ease-in 0.4s;
}
.main-tabs-submenu.active,
.main-tabs-submenu:hover {
  border-bottom-color: rgba(231, 6, 84, 0.958) ;
  color: rgba(231, 6, 84, 0.958);
  transition: all ease-in 0.4s;
  -moz-transition: all ease-in 0.4s;
  -webkit-transition: all ease-in 0.4s;
}

.jackpot-zero-top {
  height: 0 !important;
}

.live-unstarted.live-group-buttons {
  width: 100%;
  display: block;
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: inherit;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: none;
}

@media screen and (max-width: 640px) {
  .diminish-mobile-row.row {
    margin-right: 0;
    margin-left: 0;
  }
}

@media (max-width: 767px) {

  .logo {
    display: flex;
  }
  .mobile-pad-top {
    padding-top: 10px;
  }
  .navbar-nav {
    margin: 0px -15px;
}
}

.mobile-sticky-footer-slip {
  font-size: 11px;
}
.mobile-footer-slip {
  padding: 7px 7px !important;
  border-radius: 4px;
  display: block;
  /* margin-top: 10px; */
  width: auto !important;
  max-width: fit-content;
  font-weight: 300;
  font-size: 14px;
  /* margin-left: auto; */
  margin-bottom: 3px;
  /* padding-bottom: 10px; */
  margin-right: auto;
}
.bet-slip-footer-toggle {
  border-top-right-radius: 4px;
  padding-top: 10px;
  border-top-left-radius: 4px;
  background: #24367e !important;
  padding-bottom: 4px;
  font-weight: 400;
}
.mobile-footer-slip-counter {
  /* line-height: 1; */
  padding: 0px 3px;
  margin-left: 3px;
}
.bet-button {
  font-weight: 700;
  padding: 10px;
  line-height: 1;
}

.mobile-sticky-footer-slip .capture-input {
  max-width: 80px;
  height: 30px;
  color: #333333;
  font-weight: 500;
  padding-left: 5px;
  font-size: 13px;
  padding-right: 5px;
}
.alert-betslip-success-box{

}

.alert-betslip-success-box {
  border: 1px solid rgba(11, 96, 28, 0.3);
  background-color: #469866;
  color: #fff;
  font-weight: 600;
  padding: 0 8px 10px 8px;
  align-items: flex-start;
  -ms-flex-align: start;
}

.betslip-rebet-button {
  display: block;
  width: 100%;
  margin: 0 auto;
  border: 1px solid #77B18E;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.32);
  box-shadow: 0 2px 4px 0 #277a47;
  font-size: 15px;
  text-transform: uppercase;
  padding: 8px 10px;
  text-shadow: 0 1px 8px rgba(44, 121, 74, 0.6);
  transition: all 0.25s;
}

.plain-tabs {
  background: #f3f3f3 !important;
}
.plain-tabs.nav-tabs .nav-item .nav-link {
  font-weight: 600;
  color: inherit;
  border-bottom: 3px solid transparent;
}
.plain-tabs.nav-tabs .nav-item.show .nav-link, .plain-tabs.nav-tabs .nav-link.active {
  background-color: transparent;
  color: inherit;
  border-bottom-color: red !important;
}

.plain-tabs .tab-content {
  padding-left: 10px;
  padding-right: 10px;
}

.jackpot-tabs .nav-item {
  width: 50%;
}
.jackpot-tabs button {
  width: 100%;
}

li.bet-option {
  border-bottom: 1px dashed #e5e7eb;
  padding-top: 4px;
  padding-bottom: 8px;
}
.highlights-board {
  /* border-top: 10px solid #dddddd; */
  /* border-bottom: 10px  solid #dddddd; */
  /* box-shadow: 0px 2px 6px rgba(0,0,0,0.6); */
  z-index: 999999;
  margin-bottom: 0;
  margin-top: 0;
  background: #f9f9f9;
  border-bottom: 1px solid #f2f2f2;
  padding: 5px 7px;
}
.highlights .marquee-card {
  text-align: left;
  vertical-align: top;
  color: #222222;
  background-color: rgba(255,255,255,0.9);
  background-size: cover;
  border: 0;
  border-radius: .25rem;
  /* width: 98%; */
  height: 8.75em;
  /* margin-right: 8px; */
  padding: 0 !important;
  font-weight: 400;
  display: inline-block;
  box-shadow: 0 0px 0px 1px rgba(0,0,0,.1);
  margin-top: 0!important;
}

.marquee-card .market-type {
  margin-bottom: 5px;
  /* margin-left: 1em; */
  /* margin-right: 1em; */
  font-size: 12px;
  display: flex;
}

.marquee-card .market-type .line-span {
  border-bottom: 1px solid rgba(0,0,0,.3);
  flex: 1 1;
  margin-bottom: 6px;
}

.marquee-card .market-type .market-name-span {
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #adadad;
  max-width: 93.59%;
  padding: 0 8px;
  overflow: hidden;
}

.marquee-card .card-option-group {
  height: 32px;
  margin-top: 1px;
  margin-bottom: 0;
  display: flex;
}


.marquee-card .btn-count-3 .ng-star-inserted{
  width: 33.3%;
}

.ng-star-inserted .option-indicator {
  display: flex;
  background: rgba(0,0,0, 0.04);
  border: 1px solid #eeeeee;
  width: 90%;
  padding: 3px 5px;
}

.marquee-card .card-result-name-text-alt {
  color: #777777;
  font-size: 11px;
}
.marquee-card .main {
  height: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  color: #555;
  text-transform: capitalize;
}

.marquee-card .image-main {
  height: 60px;
}

img.highlights-content-img {
  height: 60px;
}

.btn-count-2 .ng-star-inserted {
  width: 50%;
}

.popular-game {
  width: 190px;
  display: inline-block;
  /* margin-right: 8px; */
  padding-right: 0px;
  border-radius: 10px;
  transition: all ease-in-out 0.5s;
  -moz-transition: all ease-in-out 0.5s;
  -webkit-transition: all ease-in-out 0.5s;
  margin-bottom: 0 !important;
}

.popular-game:hover {
  opacity: 0.7;
  transition: all ease-in-out 0.5s;
  -moz-transition: all ease-in-out 0.5s;
  -webkit-transition: all ease-in-out 0.5s;
}
.popular-games:last-child {
  padding-right: 0;
}

.popular-games {
  display: flex !important;
}

.highlights-board .popular-game img {
  width: 100%;
}

.highlights-board-panel {
  height: 100px;
  background: #ffffff;
}

/* Generalized design for the popular games */
.popular-highlight-games {
  flex-wrap: nowrap;
  /* font-size: 11px; */
  overflow: auto;
  scrollbar-width: none;
}

.popular-highlight-games::-webkit-scrollbar {
        
  display: none;
}
.highlight-color {
  color: rgba(231, 6, 84, 0.958);
}

.blink-me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.banner-imgs img {
  /* height: 150px; */
}

.menu-icon {
  width: 5rem;
  margin: auto;
  /* height: 5rem; */
}
.menu-icon img {
  width: 100%;
  /* border-radius: 3px; */
  height: 30px;
  width: 30px;
  margin: auto;
}
.menu-item .menu-name {
  margin-top: 5px;
  font-size: 12px;
  text-wrap: nowrap;
  font-weight: 400;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 0;
  overflow-y: auto;
}

.header-account .bx-1 {
  /* font-size: .75rem; */
  line-height: 1.5;
  font-weight: 500;
  opacity: .7;
}

.header-account .bx-2 {
  /* font-size: 1.125rem; */
  line-height: 1.5;
  font-weight: 500;
  margin: 5px 0;
}
.header-account .bx-3 {
  margin-top: 1rem;
  display: flex;
  opacity: .8;
}
.offcanvas-header {
  display: flex;
  align-items: center;
  padding: 15px 10px;
}

.offcanvas-title {
  margin-bottom: 0;
  font-size: 14px;
  line-height: var(--bs-offcanvas-title-line-height);
  font-weight: 500;
  color: rgb(90 102 120);
  opacity: 0.8;
}

.header-account .cd a:hover, .header-account .cd div:hover, .header-account .cd-l:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.header .onboard .e {
  background-color: rgba(255,255,255,.1);
  border-color: rgba(255,255,255,0.4) !important;
  background-color: rgba(255,255,255,.21);
  color: #fff;
  flex-shrink: 1;
  border-width: 1px;
  border-color: rgb(255 255 255 0.3);
  --tw-border-opacity: .3;
  text-align: center;
  font-weight: 500;
  padding: 6px 20px;
}

.header .onboard .e {
  background-color: rgba(255,255,255,.21);
  color: #fff;
  flex-shrink: 1;
  border-width: 1px;
  border-color: rgb(255 255 255 0.3);
  --tw-border-opacity: .3;
  text-align: center;
  font-weight: 500;
}

.header .desktop .onboard a, .header .desktop .onboard button {
  white-space: nowrap;
  border-radius: .375rem;
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
  padding: .375rem 1rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.purple-bg {
  background: #8d2585 !important;
  color: #dddddd !important;
}

.in-blue-highlight {
  /* background: blue; */
}
.std-boxed-form-page {
  max-width: 500px;
  margin: 40px auto 0 !important;
  /* text-align: center; */
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 1px 0px; */
  /* border: 1px solid #f5f5f5; */
  /* background: #fdfdfd; */
}

h1.std-title {
  font-size: 24px;
  font-weight: 500;
  color: #666666;
}

.form-block .disclaimer {
  display: block;
  text-align: center;
  font-size: 12px;
  line-height: 1.5;
  --tw-text-opacity: 1;
  color: rgb(163 163 163 / 70);
}

.btn-default {
  background: #dddddd;
}

.popover-login-modal .modal-header {
  background: #fbfbfb;
  box-shadow: 0 0 5px -1px rgba(0,0,0,.43);
  -webkit-box-shadow: 0 0 5px -1px rgba(0,0,0,.43);
  border-bottom: 1px solid rgba(255,255,255, 0.7);
}

.std-carousel-image {
  max-height: 120px;
  width: 100%;
}

.is-jackpot-matches .top-matches {
  padding-right: 0;

}

.is-jackpot .c-btn-group > .c-btn {
  
  width: 80px !important;
}

.is-jackpot {
  padding-right: 0 !important;
}

.is-jackpot-bet-group-btns {
  margin-left: auto !important;
  padding-right: 0 !important;
}

.match-markets-filter {
  background-color: rgb(64 64 64);
  color: #ffffff;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  /* margin-top: 15px; */
}

.match-markets-filter .item {
  border-bottom: 2px solid transparent;
  margin-right: 5px;
  padding-right: 4px;
  padding-left: 4px;
  padding-top: 10px;
  padding-bottom: 10px;
  opacity: .7;
}

.match-markets-filter .item:hover,
.match-markets-filter .item.active {
  border-bottom-color: #ffffff;
  opacity: 1;
}

.top-matches.header.row .col-9 {
  padding-left: 0;
}


#livescore-content img.main-img {
  width: 100%;
  max-height: 250px;
}

.livescore-footer-links .footer-item {
  margin-right: 15px;
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  opacity: 0.7;
  cursor: pointer;
  border-bottom: 3px solid transparent;
}

.livescore-footer-links .footer-item.active,
.livescore-footer-links .footer-item:hover {
  border-bottom-color: rgba(231, 6, 84, 0.958) !important;
  color: rgba(231, 6, 84, 0.958) !important;
  opacity: 1;
}

/* Default for larger screens */
.icon {
  font-size: 20px;
}

.social-icon {
  font-size: 24px;
}

@media (max-width: 768px) {
  .icon {
    font-size: 18px;
  }

  .social-icon {
    font-size: 24px;
  }
}

@media (max-width: 576px) {
  .icon {
    font-size: 16px;
  }

  .social-icon {
    font-size: 20px;
  }
}
.header-login-links .top-item {
  margin-top: 5px;
  margin-bottom: 5px;
  
}

@media screen and (max-width:640px) {
  .menu-icon img {
    height: 30px;
    width: 30px;
}
.banner-imgs img {
  /* height: 75px; */
}

.highlights .marquee-card {
  height: auto;
}

.popular-game {
  max-width: 83px;
  display: inline-block;
  padding-right: 5px;
}


.marquee-card.free-bet {
  width: 240px;
}

.exclude-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 100px;
  max-width: 600px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}


.modal-dialog.mobile-betslip-modal {
  /* transform: translate(0, 20%) !important; */
  /* -moz-transform: translate(0, 20%) !important; */
  /* -webkit-transform: translate(0, 20%) !important; */
  margin: 0 !important;
  border: none;
  padding: 0 !important;
  position: fixed;
  bottom: 0;
  transform: translate(0, 0) !important;
  top: unset;
  border: 0;
  width: 100%;
}

.modal-dialog.mobile-betslip-modal .betslip-slips {
  max-height: 35vh !important;
}
.mobile-betslip-modal .bottom {
  max-height: 50vh;
}
.modal {
  padding-left: 0;
}
}

.user-profile-icon {
  background: rgba(231,6,84, 1);
  width: 20px;
  height: 20px;
  padding: 4px;
  border-radius: 100%;
}

svg.big-offcanvas-icon {
  width: 35px;
  height: 35px;
  margin-bottom: 5px;
  opacity: 0.6;
}

.offcanvas-big-icon:hover {
  background: #ffffff !important;
}

.main-header-top {
  border-bottom: 1px solid rgba(255,255,255,0.06);
  box-shadow: -1px 0 1px 1px rgba(0,0,0,0.23);
  -moz-box-shadow: -1px 0 1px 1px rgba(0,0,0,0.23);
  -webkit-box-shadow: -1px 0 1px 1px rgba(0,0,0,0.23);
}

.ps-menu-button.ps-open {
  background: #24367e !important;
  color: white !important;
}

.header-menu li {
  opacity: 0.8;
  border-bottom: 3px solid transparent;
  margin-bottom: 0 !important;
}

.header-menu li.active, .header-menu li:hover {
  opacity: 1;
}

.red-bg {
  background: rgba(231,6,84, 1) !important;
  color: #ffffff !important;
}

.red-bg.btn {
  border-color: rgb(187, 2, 67) !important;
}

.red-text {
  color: rgb(187, 2, 67) !important;
}

.change-display {
  flex-wrap: nowrap;
  overflow: auto;
  scrollbar-width: none;
}

.std-medium-width-block {
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
  border: 1px solid rgba(0,0, 0, 0.051);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 2px 0px rgba(0,0,0,0.1);
  margin-top: 20px;
}

.input-field {
  margin-bottom: 20px;
}

label {
  text-transform: capitalize;
}

.accordion__panel {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.big-icon-container {
  position: relative;
  display: flex;
  align-items: center;
}

.big-icon-arrows {
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.big-icon-list {
  display: flex;
  gap: 1rem;
}
::-webkit-scrollbar-thumb {
  background: #24367e;
  border-radius: 4px;
  box-shadow: rgba(0,0,0.1);
}
::-webkit-scrollbar-track {
  background: #e2e2e2;
}
.big-icon-item {
  padding: 10px 4px 4px;
  align-items: center;
  color: #ddd;
  font-weight: 600;
  text-align: center;
  position: relative;
  border-bottom: 3px solid transparent;
  margin-bottom: 0 !important;
  display: flex !important;
  justify-content: center;
  opacity: 0.9;
  transition: opacity 0.2s, color 0.2s;
}

.big-icon-icon {
  
}

.big-icon-item.active {
  opacity: 1; 
}

.big-icon-item.active::after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  right: 0;
  height: 3px;
  background-color: #ffffff;
  opacity: 1;
}

.big-icon-item:not(.active)::after {
  opacity: 0;
  transition: opacity 0.2s;
}

.big-icon-name {
  /* font-size: 0.85rem; */
  color: #ffffff;
  opacity: 0.7;
  font-weight: 400;
  flex-flow: wrap;
  display: flex;
  white-space: nowrap;
  flex-wrap: wrap;
}

.big-icon-icon img {
  width: 24px;
  height: 24px;
  opacity: 0.8; 
  transition: opacity 0.2s, filter 0.2s;
}

.big-icon-item.active .big-icon-name {
  color: #ffffff;
}

.big-icon-item.active .big-icon-icon img {
  opacity: 1; /* Fully visible icon on active */
  filter: brightness(0) invert(1); /* Makes the icon appear white */
}


.big-icon-scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.big-icon-scrollbar-hide::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

.carousel-control-next, .carousel-control-prev {
  
  width: 7%;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  background-color: rgba(0,0,0,0.6);
  /* padding: 5p; */
  border-radius: 100%;
  /* border: 13px solid rgba(0,0,0,0.6); */
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  display: inline-block;
  width: 4rem;
  height: 4rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.no-bet-btn:hover, button.no-bet-btn, button.no-bet-btn:hover, .no-bet-btn, button.locked-button, .locked-button, button.locked-button:hover {
  background-color: #dddddd !important;
  min-width: 50px;
  min-height: 40px;
  text-align: center;
  opacity: 0.4;
  color: #666666 !important;
  border: 1px solid #cccccc;
}

.slots-filter {
  /* border: 1px solid #30353b; */
  box-sizing: border-box;
  height: 100vh;
  left: 0;
  margin-right: .0;
  min-width: 16.375em;
  overflow-y: auto;
  padding-bottom: 7rem;
  /* position: fixed; */
  width: 16.375em;
}

.slots-content {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 10rem;
  /* position: fixed; */
  right: 0;
  width: calc(100% - 16.25em);
}

@media screen and (min-width: 1160px) {
  .no-body-header-spacing.amt {
    /* margin-top: 130px !important; */
  }
}

.prematch-started {
  opacity: 0.7;
}


.no-big-icon-nav .amt {
  /* margin-top: 60px !important; */
  margin-top: 60px !important;
}
@media screen and (max-width: 648px) {
  .no-big-icon-nav .amt {
    margin-top: 74px !important;
}
  .big-icon-item {
    padding: 5px 4px 4px;
    
}
.big-icon-icon img {
  width: 20px;
  height: 20px;
}

.top-login-btn {
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #6c5cc0 !important;
  font-weight: 500;
  padding: 5px 15px !important;
}
.header-login-links .onboard {
  margin-top: 2px !important;
}

.amt {
  /* margin-top: 75px; */
  margin-top: 126px;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  
  width: 2rem;
  height: 2rem;
  padding: 7px;
}
.popular-game {
  max-width: 83px;
  display: inline-block;
  padding-right: 0;
  padding-left: 2px;
}

.no-sports-wide-width {
 width: 100%; 
}

.highlights-board {
  padding: 5px 0px;
}

.won-gif-container {
  position: absolute;
  top: 20px; 
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  width: 50%; 
  display: flex;
  justify-content: center; 
}

.won-gif {
  width: 100%; 
  height: 200px; 
}


}

.no-header {
  margin-top: 0 !important;
}

