
.surebox-container {
    background: #0b121b;
    color: white;
    min-height: 100vh;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.surebox-section {
    background: #15202b;
    width: 100%;
    max-width: 1000px;
    border-radius: 0.5rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.surebox-header {
    display: flex;
    justify-content: space-between; 
    align-items: center; 
    padding: 0 1rem; 
    margin-bottom: 1rem;
}

.surebox-title {
    font-size: 2rem;
    font-weight: bold;
    color: #ffffff;
    margin: 0;
    padding-right: 1rem; 
}

.surebox-sound-icon {
    font-size: 3rem; 
    cursor: pointer; 
    color: #ffffff;
    margin-left: 1rem; 
}

.surebox-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

@media (min-width: 768px) {
  .surebox-content {
      flex-direction: row;
      align-items: flex-start;
  }

  .surebox-grid {
      flex: 2; 
      margin-bottom: 0;  
  }

  .surebox-controls {
      flex: 1;  
      max-width: none;  
      margin-top: 0;  
  }
}
  
.surebox-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 0.5rem; 
    width: 100%;
    max-width: 1000px;
    margin-bottom: 2rem;
    transition: all 0.3s ease;
}

.surebox-item {
    width: 100%;
    padding-top: 100%; 
    position: relative;
    background: #0b121b;
    border: 1px solid #456185;
    border-radius: 0.25rem;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .surebox-item img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 80%;
    max-height: 80%;
  }

.surebox-item:hover {
    transform: scale(1.05);
    background-color: #204973;
}

.surebox-item-open {
    background-color: #456185;
}

.surebox-item-closed {
    background-color: #102f56;
}

.surebox-controls {
    background-color: #102f56;
    border-radius: 0.5rem;
    padding: 1rem;
    width: 100%;
    max-width:400px; 
    display: flex;
    flex-direction: column;
}

.surebox-controls .flex-row {
    display: flex;
    gap: 1.5rem;
}

.surebox-controls button {
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.surebox-controls button:hover {
    transform: scale(1.05);
}

.odds-display {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 0.9rem;
    background: rgba(0, 0, 0, 0.6);
    padding: 0.3rem 0.5rem;
    border-radius: 0.3rem;
  }
  
  .inner-button {
    background-color: white;
    color: black;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    font-weight: 600;
    transition: transform 0.2s ease, background-color 0.3s ease;
  }
  
  .inner-button:hover {
    background-color: #f0f0f0;
    transform: scale(1.05);
  }
  
  .surebox-notify-outcome {
    max-width: 250px;
    margin: 10px auto;
    text-transform: capitalize;
    text-align: center;
    color: rgba(255, 255, 255, 0.8); 
    border-radius: 20px;
    transition: all ease-in-out 500ms;
    height: 48px;
    padding: 10px;
    opacity: 0.8; 
    pointer-events: none; 
  }
  
  .surebox-won {
    background: rgba(39, 118, 20, 0.0); 
    border: 1px solid rgba(31, 92, 18, 0.5);
  }
  
  .surebox-lost {
    background: rgba(247, 27, 27, 0.0); 
    border: 1px solid rgba(214, 23, 23, 0.5);
  }
  
  