:root {
    --coin_front: '../img/'
}

.rotating-images-wrapper {
    /* display: flex; */
    clear: both;
    position:relative;
}

.rotating-image-container {
    position:relative;
    min-width: 200px;
    min-height: 200px;
}
.rotating-img {
    /* width: auto; */
    height: 150px;
    padding: 0%;
    width: 150px;
    margin: auto;
    position: relative;
    margin-bottom: 50px;
    margin-top: 50px;
}

.rotating-img .coin-image {
    position: absolute;
    top: 0;
    width: 100%;
    background-size: cover;
    height: 100%;
    /* left: 50%; */
    /* transform: translate(-50% 0); */


}

.coin-image.heads {
    background-image: url("../img/casino/head.png");
    transform: translateZ(5px);
}

.coin-image.tails {
    background-image: url("../img/casino/tail.png");
    transform: translateZ(5px);

}

.higher-z {
    z-index: 999;
}
.user-choice {
    color: #143ee4;
}

.user-choice.tails {
    color: #bb0243;
}
.rotating-img.is-spinning {
    transform-style: preserve-3d;
    animation: rotation .3s linear infinite;
    transition: all .11s;
     
}

.prep-to-start {
    animation: prepToStart 2s linear infinite;

}

.coin-settled {
    /* perspective: 500px; */
    /* transform: rotateX(60deg); */
}

@keyframes prepToStart {
    0% {
        transform: perspective(1000px) rotateY(20deg);
      }
      100%{ transform: perspective(1000px) rotateY(360deg);}
}
@keyframes rotation {
    0% {
        transform: perspective(1000px) rotateY(0deg);
      }
      100%{    transform: perspective(1000px) rotateY(11500deg);
    }
}

  .sure-coin-header {
    background: rgba(255,255,255,0.06);
    height: 40px;
    padding: 5px 0px;
    font-weight: 500;
    border-radius: 5;
    margin-left: 0;
    margin-right: 0;
    color: #fff;
}

.sure-alert.error {
    background: #f8d7da;
    border: 1px solid #f5c6cb;
    border-radius: 0 0 6px 6px;
    color: #721c24;
    left: auto;
    max-width: 200px;
    padding: 20px;
    position: absolute;
    right: auto;
    top: 30px;
    left: 50%;
    transform: translate(-60px, 10px);
    -moz-transform: translate(-60px, 10px);
    -webkit-transform: translate(-60px, 10px);
    z-index: 99;
}
.casino-service-sure-coin {
    background-color: rgba(0,0,0,0.6);
    padding: 10px 10px;
    position: relative;
}
.coin-settings {
    background: rgba(255,255,255,0.06);
    padding-left: 6px;
    margin-bottom: 7px;
    border-radius: 8px;
    color: #fff;
    padding-top: 8px;
    margin-top: 8px;
}
.launched-sure-coin{
    
}
.sure-coin-amount-input-section {
    background: rgba(0, 0, 0, 0.8);
    border-radius: 20px;
    padding: 5px 10px;
}

.casino-service-sure-coin .pickBtn {
    background-color: #143ee4;
    color: white;
    padding: 5px 10px;
    border-radius: 7px;
    /* width: 100px; */
}

.casino-service-sure-coin .pickBtn.tail {
    background-color: #bb0243;
    color: white;
}

.user-picked-btn {
    position: absolute;
    font-size: 35px;
    color: #FFB200;
    top: 8%;
}
.tail.selected-btn {
    background-color: #bb02438a !important;
    color: #ffffff !important;
}

.head.selected-btn {
    background-color: #143ee47d;
}

.sound-interact-prompt-cover {
    position: absolute;
    height: calc(100%);
    background: rgba(0, 0, 0, 1);
    z-index: 9999;
    width: 100%;
    text-transform: uppercase;
}

.sound-selector button {
    width: 80px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-transform: uppercase;
    font-weight: bold;
}
.sound-interact-prompt-cover .sound-selector {
    margin-top: 20%;
    max-width: 200px;
}
.won-gif-container {
    position: absolute;
    width: 200px;
    top: 0;
    left: 35%;
    transform: translate(-35% 0%);
}

.network-changes {
    height: 0;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    top: 0;
    background: #fff3cdd4;
    width: 200px;
    color: #930404;
    font-weight: 600;
    overflow: hidden;
    text-align: center;
    transition: all ease-in-out 500ms;
    -moz-transition: all ease-in-out 500ms;
    -webkit-transition: all ease-in-out 500ms;

}

.network-changes.show {
    
    padding: .75rem 1.25rem;
    border-radius: 3px;
    height: 40px;
    transition: all ease-in-out 500ms;
    -moz-transition: all ease-in-out 500ms;
    -webkit-transition: all ease-in-out 500ms;}

.network-changes.just-back {
    background-color: #cce5ff;
    color: #004085;
}
@media screen and (min-width: 760px) {
  
}

.css-rehya9-MuiSwitch-root {
    display: inline-flex;
    width: 87px !important;
    height: 40px !important;
    overflow: hidden;
    padding: 13px;
    box-sizing: border-box;
    position: relative;
    flex-shrink: 0;
    z-index: 0;
    vertical-align: middle;
}

.css-161ms7l-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
    -webkit-transform: translateX(40px) !important;
    -moz-transform: translateX(40px) !important;
    -ms-transform: translateX(40px) !important;
    transform: translateX(40px) !important;
}

.css-17jyosd-MuiSwitch-thumb {
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
    -moz-box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
    -webkit-box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
    background-color: currentColor;
    width: 25px !important;
    height: 25px !important;
    border-radius: 50%;
}

.diminish-mobile-row>* {
    padding-left: 0;
    padding-right: 0;
}

.surecoin-logo-img {
    width: 20px;
    display: inline-block;
    margin-right: 7px;
    color: #bb0243;
}
.win-state {
    padding: 10px;
}
.info-box.chosen-box {
    color: #FFB200;

}
.info-box {
    padding: 5px 15px;
    border: 1px solid ;
    margin-top: 3px;
    border-radius: 3px;
}
.bet-info {
    position: absolute;
    background: rgba(255,255,255, 0.061);
    top: -55px;
    left: -2px;
    padding: 5px 8px;
    border: 1px solid rgba(255,255,255, 0.051);
    border-radius: 2px;
    text-align: center;
}
.win-state.won {
    background: green;
}


.win-state.lost {
}
.autopicks-left {
    max-width: 90px;
}
.btn-place-surecoin-bet {
    padding: 10px 15px;
    background: #FFB200 !important;
    color: #604300 !important;
    font-size: 15px;
    font-weight: 700;
    min-width: 250px;
    opacity: 1 !important;
    /* text-shadow: 0px 1px 2px white; */
    text-transform: uppercase;
    float: right;
    transition: all ease-in-out 0.3s;
}

.bets-timer-empty-holder {
    height: 23px;
}
.btn-place-surecoin-bet:hover {
    opacity: .7;
    transition: all ease-in-out 0.3s;
}

.time-left-text {
    
}

.btn-place-surecoin-bet.betplaced {
    background: red !important;
    color: #ffffff !important;
    border-color: #ff4d4d !important;
}
.casino-service-sure-coin .pickBtn {
    padding: 10px 15px;
}

.coin-quick-stats {
    position: absolute;
    right: 10px;
    z-index: 99;
    border: 1px solid rgba(255, 255,255, 0.0612);
    color: rgba(255,255,255, 0.7);
    padding: 5px;
    border-radius: 3px;
    line-height: 30px;
    background: rgba(255,255,255,0.031);
    font-size: 1.3rem;
    top: 3px;
}
.coin-taking-bets{
	position: absolute;
	bottom: 10px;
	border: 1px solid #fff;
	color: #fff;
	padding: 12px;
	border-radius: 10px;
    line-height: 23px;
    min-width: 25%;
}

.time-left {
    color: #FFB200;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 1.7;
}
.director-message {
    bottom: 15%;
    font-size: 18px;
    left: 50%;
    position: absolute;
    text-transform: uppercase;
    transform: translate(-50%,60%);
    width: 100%;
    text-align: center;
}
.no-picked-disabled {
    background: rgba(255,255,255) !important;
    border: none !important;
    color: #444 !important;
    opacity: 1 !important;
}
button:disabled,
button[disabled]{
  cursor: not-allowed;
}

.autopick-hint {
    color: #FFB200;
    font-size: 10px;
    margin-top: 7px;
    font-weight: 500;
    text-transform: uppercase;
}
.notify-win {
    max-width: 250px;
    margin: 0px auto;
    text-transform: capitalize;
    overflow: hidden;
    text-align: center;
    height: 0;
    color: white;
    border-radius: 50px;
    transition: all ease-in-out 500ms;
    -moz-transition: all ease-in-out 500ms;
    -webkit-transition: all ease-in-out 500ms;
}
.notify-win.won, .notify-win.lost {
    height: 48px;
    border: 1px solid #277614;
    padding: 5px 10px;
    transition: all ease-in-out 500ms;
    -moz-transition: all ease-in-out 500ms;
    -webkit-transition: all ease-in-out 500ms;
}

.notify-win.lost {
    border: 1px solid rgb(247, 27, 27);
    background: rgb(247, 27, 27);

}

.notify-win .won-amount {
    width: 100px;
    border-radius: 19px;
    padding: 2px 6px;
    line-height: 1.1;
    font-size: 12px;
    margin-left: 10px;
   
}
.notify-win.won {
    background-color: #00800099;
}
.notify-win.won .won-amount {
    background: #28a909;
     border: 1px solid #8cfb71;
}

.notify-win.lost .won-amount {
    background: rgb(247, 27, 27) !important;
    border:rgb(246, 47, 47)
}
@keyframes growText {
    from {
      width: 13px;
    }
    to {
      font-size: 25px;
    }
  }
.won-expanding {
    z-index: 999;
    font-weight: 700;
    font-size: 16px;
    /* animation: growText 3s infinite alternate; 3 seconds, alternating direction */
  }
.won-expanding-text {
    
}
.notify-win.lost {
    background-color: #c3080899;

}
.notify-win-container {
    z-index: 9999;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0px);
    -moz-transform: translate(-50%, 0px);
    -webkit-transform: translate(-50%, 0px);
    top: -50px;
    z-index: 999999;
}
@media screen and (max-width: 648px) {
    .surecoin-body {
        width: 98%;
        margin-left: auto;
        margin-right: auto;
        /* margin-top: 5%; */
    }
    .rotating-img {
        width: 150px;
        padding-bottom: 0 !important;
    }
    .surecoin-logo-img {

    }

    .director-message {
        position: absolute;
        bottom: 18%;
        transform: translate(-50%, 44%);
        font-size: 14px;
        left: 49%;
        width: 100%;
        text-transform: uppercase;
        text-align: center;
    }

    .user-menu-col {
        width: auto;
        margin-top: 9px;
        margin-right: 4px;
    }

    

    .user-amount-input {
        max-width: 100px !important;
    }

    .btn-place-surecoin-bet {
        float: none;
        margin-right: auto;
        margin-left: auto;
        margin-top: 5px;
        width: 100%;
    }
    .casino-service-sure-coin {
        padding: 10px 1px;
    }
    .css-rehya9-MuiSwitch-root {
        width: 70px !important;
        height: 40px !important;
    }
    .coin-quick-stats {
        right: 0px;
    }

    .sound-interact-prompt-cover .sound-selector {
        margin-top: 40%;
        max-width: 200px;
    }

}
.coin-count-down {
	position: absolute;
	color: yellow;
	padding: 10px;
	font-weight: 500;
	opacity: 0.9;
}