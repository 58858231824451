.casino-sidebar {
    border-right: 1px solid #a6a6a6;
    min-height: 100vh;
    box-shadow: 1px 0 2px rgba(0,0,0,0.2);
}
.casino-games-liting {
    /* display: inline-flex; */
}
.casino-game {
    width: 20%;
    margin-left: 0;
    /* margin-right: 5px; */
    padding-right: 0;
    padding-left: 5px;
    float: left;
    margin-bottom: 5px;
    color: #777;
    position: relative;
    font-weight: 400;
}
.casino-game img {
    border-radius: 5px;
    /* height: 200px; */
}

.game-height-fix {
    /* max-height: 150px; */
    /* overflow: hidden; */
}
.game-buttons {
    position: absolute;
    left: 50%;
    translate: -50% 30%;
    right: auto;
    top: 10%;
    background: rgba(0,0,0, 0.4);
    display: none;
    padding: 10px 2px;
    padding-right: 40px;
    border-radius: 4px;
    padding-left: 40px;
    /* width: 100%; */
}

.game-buttons .casino-cta {
    display: block;
    margin-bottom: 4px;
    width: 100px;
    letter-spacing: 1px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
}

.casino-demo-btn {
    background-color: #f0f0f0 !important;
    border: 2px solid #eee !important;
    color: #666 !important;
    /* text-transform: uppercase; */
}

.casino-game:hover .game-buttons {
    display: block;
}

.casino-category-header {
    background: #24367e;
    margin-bottom: 5px;
    /* box-shadow: 0 2px 0px rgba(0,0,0,0.12); */
    /* -moz-box-shadow: 0 2px 0px rgba(0,0,0,0.12); */
    /* -webkit-box-shadow: 0 1px 0px rgba(0,0,0,0.062); */
    border-bottom: 1px solid rgba(0,0,0,0.124);
    font-size: 15px;
    font-weight: 700;
    padding-top: 8px !important;
    color: #fff;
    padding-bottom: 8px !important;
    text-transform: uppercase;
}
.category-products {
    /* margin-bottom: 5px; */
}

.casino-list-block {

}
.casino-sidebar .menu-card {

}

.casino-list-block {
    display: block;
    border-radius: .375rem;
    --tw-bg-opacity: 1;
    /* background-color: rgb(255 255 255 / var(--tw-bg-opacity)); */
    padding: 0;
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / .05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    /* box-shadow: 0 0 2px rgba(0,0,0, 0.1); */
}
.casino-list-block ul li, .casino-list-block ul a {
    margin-left: 5px;
    margin-right: 0;
    display: flex;
    width: auto;
    gap: .95rem;
    background-color: transparent;
    padding-left: 0;
    padding-right: .5rem;
    font-size: 15px;
    line-height: 1.5;
    font-weight: 400;
    color: rgba(38,38,38,.7);
    opacity: 1;
    margin-bottom: 0px;
    /* border-bottom: 1px solid #eee; */
    padding-bottom: 8px !important;
    border-radius: 0;
    padding-top: 8px !important;
    border-bottom: 1px solid #ebebeb;
}
.casino-list-block ul li:last-child {
    border-bottom: none !important;
    margin-bottom: 0;
    padding-bottom: 0;
}
.casino-list-block ul li:hover, .casino-list-block ul li.active {
    background-color: #eee;
    color: #444;
    /* transition: all ease-in-out 0.3s; */
    /* padding-left: 10px; */
}

.launched-casino-wrapper {
    background-color: rgb(11, 18, 27);
    min-height: 100vh;
    color: #ffffff;
    overflow-y: auto;
    overflow-x: clip;
}

.casino-page-close {
    background-color: rgba(255, 255, 255, 0.25);
    color: #ffffff;
    display: inline-block;
    padding: 5px;
    font-size: 15px;
    margin-top: 5px;
    border-radius: 100%;
    float: right;
}

.launched-game-header {

}

.casino-launched-game-frame {
    height: calc(100vh - 10vh);
}

.launched-casino-wrapper .amt {
    /* margin-top: 0 !important; */
}
.casino-icon {
    /* font-size: 10px; */
    width: 15px;
    margin-right: 3px;
}
.casino-class-header {
    padding-bottom: 6px;
    background: #ececec;
    /* color: white !important; */
    padding-top: 6px;
    border-radius: 0;
    padding-left: 7px;
    border-top: 1px solid #c6c6c6;
    border-bottom: 1px solid #c6c6c6;
}
.casino.banner-imgs img {
    /* height: 170px; */
}

.game-launch-issue {
    position: absolute;
    width: 95%;
    top: -4px;
    left: 3%;
    right: auto;
}
.casino-sidebar-items {
    padding-left: 8px;
    padding-right: 8px;
}
.game-image-wrapper {
    width: 100%;
    height: 150px;
    overflow: hidden;
    position: relative;
}

.virtual-game-image {
    width: 100%; /* Cover the full width */
    height: 100%; /* Cover the full height */
    object-fit: cover; /* Ensure the image fits without stretching */
    object-position: center; /* Center the image within the container */
  
}
@media screen and (max-width:648px) {
    .game-image-wrapper {
        width: 100%;
        height: 100px;
        overflow: hidden;
        position: relative;
    }
    .game-buttons {
        position: absolute;
        left: 50%;
        translate: -50% 10%;
        right: auto;
        top: 10%;
        background: rgba(0,0,0, 0.6);
        display: none;
        padding: 5px 10px;
        padding-right: 10px;
        border-radius: 4px;
        padding-left: 10px;
    }
    .casino-category-header {
        /* background: #ffffff; */
        margin-bottom: 4px;
        /* box-shadow: 0 2px 0px rgba(0,0,0,0.12); */
        /* -moz-box-shadow: 0 2px 0px rgba(0,0,0,0.12); */
        /* -webkit-box-shadow: 0 1px 0px rgba(0,0,0,0.062); */
        border-bottom: 1px solid rgba(0,0,0,0.124);
    }
    .casino-game {
        width: 20%;
        margin-left: 0;
        /* margin-right: 5px; */
        padding-right: 0;
        padding-left: 4px;
        float: left;
        margin-bottom: 5px;
        color: #777;
        position: relative;
        font-weight: 400;
    }
    .casino.banner-imgs img {
        height: inherit;
    }

    .banner-imgs img {
        height: 80px !important;
    }
    .casino-game {
        width: 50%;
    }

    .casino-game img {
        /* height: 150px; */
    }
    .game-height-fix {
        max-height: 100px;
        overflow: hidden;
    }
    
}

.page-title {
    background-color: #ffffff;
}
